import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar2() {
  return (
    <nav className="nav2">
      <ul>
		<CustomLink to="/exhibitions">
			<span class="deco">ᔕ</span>&nbsp;exhibitions&nbsp;<span class="deco">ᔓ</span>
		</CustomLink>    
		<CustomLink to="/shop">
			<span class="deco">ᔕ </span>&nbsp;shop&nbsp;<span class="deco">ᔓ</span>
		</CustomLink>
        <CustomLink to="/contact">
			<span class="deco">ᔕ </span>&nbsp;contact&nbsp;<span class="deco">ᔓ</span>
		</CustomLink>
      </ul>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  const linkDestination = isActive ? "/" : to;

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={linkDestination} {...props}>
        {children}
      </Link>
    </li>
  )
}
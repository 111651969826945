import homeImg from "../images/by my side homepage2.webP";
import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Home() {
	return (
	<div>
		<Link to="/Bio" className="site-title">
				<img className="homeImg" src={homeImg} alt="homeImg" />	
		</Link>
	</div>
	)
}

function CustomLink({ to, children, ...props }) {
	const resolvedPath = useResolvedPath(to)
	const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  
	return (
	  <li className={isActive ? "active" : ""}>
		<Link to={to} {...props}>
		  {children}
		</Link>
	  </li>
	)
  }
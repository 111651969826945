import bioImg from "../images/by my side homepage_seuil2.jpg";
import contactFrame from "../images/frame2.png";
import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function contact() {
	return (
		<div>
		<Link to="/" className="bio">
			<img className="bioImg" src={bioImg} alt="bioImg" />
		</Link>
		


		
		<div className="contactText">
			<img className="contactFrame" src={contactFrame}/>
			<span className="contactPicto">✉️</span>&nbsp;mglassfield@gmail.com<br></br> 
			<span className="contactPicto">↳</span>
			<Link className="linkInsta" to="https://www.instagram.com/monaglassfi3ld/"> instagram<br></br></Link>
		</div>

		</div>
	)
}
import React, { useEffect, useRef } from 'react';
import '../App.css'; // Assuming you have a CSS file to handle basic styling

const MovingChar = () => {
  const velocities = [
    { dx: 1, dy: 1 }
  ];

  // Refs to access the character
  const charRefs = [
    useRef(null)
  ];

  useEffect(() => {
    const charSize = 25; // Assume the character is roughly 25px by 25px
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let rotationAngle = 0; // Initial rotation angle

    const updateWindowSize = () => {
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
    };

    // Function to move and rotate the character
    const moveChar = (charRef, index) => {
      let xPos = Math.random() * (windowWidth - charSize);
      let yPos = Math.random() * (windowHeight - charSize);

      const updatePosition = () => {
        xPos += velocities[index].dx;
        yPos += velocities[index].dy;
        rotationAngle += 2; // Increment the rotation angle

        // Reverse direction if the character hits the boundary
        if (xPos <= 0 || xPos >= windowWidth - charSize) {
          velocities[index].dx *= -1;
          xPos = Math.max(0, Math.min(xPos, windowWidth - charSize)); // Clamp the position
        }
        if (yPos <= 0 || yPos >= windowHeight - charSize) {
          velocities[index].dy *= -1;
          yPos = Math.max(0, Math.min(yPos, windowHeight - charSize)); // Clamp the position
        }

        // Apply the new position and rotation to the character
        if (charRef.current) {
          charRef.current.style.left = `${xPos}px`;
          charRef.current.style.top = `${yPos}px`;
          charRef.current.style.transform = `rotate(${rotationAngle}deg)`; // Apply rotation
        }

        // Use requestAnimationFrame for smooth animation
        requestAnimationFrame(updatePosition);
      };

      // Start the animation
      updatePosition();
    };

    // Move all characters
    charRefs.forEach((charRef, index) => {
      moveChar(charRef, index);
    });

    // Update window size on resize
    window.addEventListener('resize', updateWindowSize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  return (
    <div>
      <span ref={charRefs[0]} className="moving-char">✦</span>
    </div>
  );
};

export default MovingChar;
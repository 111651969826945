import img1 from "../images/low tide .webP";
import img2 from "../images/little dark.webP";
import img3 from "../images/lacquer heart.webP";
import img4 from "../images/kiss.webP";
import img5 from "../images/embrace .webP";
import img6 from "../images/pollen.webP";
import img7 from "../images/blue shift.webP";
import img8 from "../images/calico.webP";
import img9 from "../images/obsydian .webP";
import img10 from "../images/untitled 1.webP";
import img11 from "../images/foal .webP";
import img12 from "../images/In the small valley.webP";
import img13 from "../images/memoriam .webP";
import img14 from "../images/dartmoor.webP";
import img15 from "../images/pearl.webP";
import img16 from "../images/untitled 2.webP";
import img17 from "../images/koenji.webP";
import img18 from "../images/by my side.webP";
import img19 from "../images/as it was in the beginning.webP";
import img20 from "../images/untitled 3.webP";
import img21 from "../images/two .webP";
import { Link } from "react-router-dom";


export default function shop() {
	return  (
		<div className="shop">
		<img className="img1" src={img1} alt="img1" />
		<div className="title1">« Low Tide » 2024, oil on canvas, 50 x 32, £350&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span class="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link>
		</div> 
		<img className="img2" src={img2} alt="img1" />
		<div className="title1">« Little dark » 2024, oil on canvas, 25 x 25, £280&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link>
		</div> 
		<img className="img3" src={img3} alt="img1" />
		<div className="title1">« Lacquer heart » 2023, oil on wood, 32 x 19.5, £280&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link>
		</div> 
		<img className="img4" src={img4} alt="img1" />
		<div className="title1">« Kiss » 2023, graphite, 10 x 15, £20&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img5" src={img5} alt="img1" />
		<div className="title1">« Embrace » 2023, oil on canvas board, 25 x 30, £200&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img6" src={img6} alt="img1" />
		<div className="title1">« Pollen » 2024, oil on canvas, 20 x 18, £180&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img7" src={img7} alt="img1" />
		<div className="title1">« Blue shift » 2024, oil on canvas board, 18 x 18, sold </div> 
		<img className="img8" src={img8} alt="img1" />
		<div className="title1">« Calico » 2023, oil on canvas, 19 x 16, £300&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img9" src={img9} alt="img1" />
		<div className="title1">« Obsydian » 2023, oil on canvas board, 15 x 15, £200&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img10" src={img10} alt="img1" />
		<div className="title1">« Untitled 1 » 2023, graphite, 21 x 14.5, £150&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img11" src={img11} alt="img1" />
		<div className="title1">« Foal » 2023, oil on gesso board, 18 x 24, sold </div> 
		<img className="img12" src={img12} alt="img1" />
		<div className="title1">« In the small valley » 2023, oil on canvas board, 24 x 16, sold</div> 
		<img className="img13" src={img13} alt="img1" />
		<div className="title1">« Memoriam » 2023, oil on wood, 40 x 26.5, £400&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img14" src={img14} alt="img1" />
		<div className="title1">« Dartmoor » 2023, oil on canvas board, 15 x 10, £100&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img15" src={img15} alt="img1" />
		<div className="title1">« Pearl » 2023, oil on canvas board, 22.5 x 14, £100&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img16" src={img16} alt="img1" />
		<div className="title1">« Untitled 2 » 2023, oil on canvas board, 39 x 26, £300&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img17" src={img17} alt="img1" />
		<div className="title1">« Koenji » 2023, oil on wood, 40 x 26.5, £400&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img18" src={img18} alt="img1" />
		<div className="title1">« By my side » 2024, oil on canvas, 40 x 26.5, £400&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img19" src={img19} alt="img1" />
		<div className="title1">« As it was in the beginning, is now and ever shall be, world without end » 2024, oil on wood, 40 x 26.5, £400&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img20" src={img20} alt="img1" />
		<div className="title1">« Untitled 3 » 2024, oil on canvas, 39 x 26, £300&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		<img className="img21" src={img21} alt="img1" />
		<div className="title2">« Two » 2023, ball pen on paper, 24 x 30, £100&nbsp;	  
			<Link className="linkToBuy" to="https://www.stripe.com">
				<span className="arrowShop">⭤</span> 
				&nbsp;purchase
			</Link></div> 
		</div>
		)
}
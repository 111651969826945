import bioImg from "../images/by my side homepage_seuil2.jpg";
import { Link } from "react-router-dom"

export default function Exhibitions() {
	return (
		<div>

		
		<Link to="/" className="bio">
			<img className="bioImg" src={bioImg} alt="bioImg" />
		</Link>
		
		<div className="exhibitionText">
				<p>Solo and Duo exhibitions<br></br>

				<p><span className="exhibitionStar">✧</span> Odd Balade, <span className="infoExhibition">Trypheme and friends, Bagnoler, Paris France, 2024.</span></p>
				<p><span className="exhibitionStar">✧</span> Blue Shift, <span className="infoExhibition">Studio9 Totnes, England 2024.</span></p>
				<br></br><br></br>
				</p>
				<p>Group exhibitions<br></br>
 
				<p><span className="exhibitionStar">✧</span> Forever Just For One Moment, <span className="infoExhibition">Kaamer Gallery., Koenji, Tokyo Japan, 2023.</span></p>
				<p><span className="exhibitionStar">✧</span> Soft Touch, <span className="infoExhibition">Avalon Cafe Bermondsey, London England 2021.</span></p>
				<p><span className="exhibitionStar">✧</span> Pelukeria, <span className="infoExhibition">Voiture 14, Marseille France, 2021.</span></p>
				</p>
				
		</div>

<div className="exhibitions-background">
		</div>
		</div>
	)
}

import bioImg from "../images/by my side homepage_seuil2.webP";
import { Link } from "react-router-dom"

export default function Bio() {
	return (
		<div>		
		<Link to="/" className="bio">
			<img className="bioImgBio" src={bioImg} alt="bioImg" />
		</Link>
		<div className="bioText">
			<p className="indented-text">
Mona Glassfield (b. 1996, Totnes, Devon) is an artist currently based in London UK.<br></br>
She received her BA from The University of Brighton in 2018.<br></br>
Taking inspiration from ancient mythology, poetry, 90’s manga, her practice involves re-piecing memories and emotions from her subconscious to form speculative worlds where interactions between fictional characters can represent abstract thoughts and convey hidden emotions.
</p> 
				 
		</div>

		<div className="bio-background">
			
		</div>
		</div>
	)
}

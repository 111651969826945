import Navbar from "./Navbar"
import Navbar2 from "./Navbar2"
import Home from "./pages/Home"
import Exhibitions from "./pages/Exhibitions"
import Shop from "./pages/Shop"
import Contact from "./pages/Contact"
import Bio from "./pages/Bio"
import MovingImage from "./pages/MovingImage"
import React from "react"
import { Route, Routes } from "react-router-dom"


function App() {

  return (
    <>

      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
		  <Route path="/exhibitions" element={<Exhibitions />} /> 
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

	  </div>
      <div className="subContainer">

		<Routes >
			<Route path="/bio" element={<Bio />} />
		</Routes>

	  </div>
	  <Navbar2 />
	  
	 <MovingImage/>
    </>
  )
}

export default App